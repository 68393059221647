import CSS from './impact-figures.module.css'

export const ImpactFigures = ({ children, theme }) => {
    return (
        <section className={`${CSS.container} block bg-${theme}`}>
            <div className={`${CSS.impactFigures} container`}>
                {children}
            </div>
        </section>
    )
}


export const ImpactFigure = ({value, label}) => {
    return (
        <div className={CSS.impactFigure}>
            <p className={`impactFigureValue fs-0 fw-200 c-primary`}>{value}</p>
            <p className={`caps`}>{label}</p>
        </div>
    )
}

export const ImpactFigureSpacer = () => <div className={CSS.spacer}></div>